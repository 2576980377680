enum FeatureFlags {
  HT_36_ENABLE_TRAVEL_ORDER_PAGE_20230521_TEMP = 'ht_36_enable_travel_order_page_20230521_temp',

  PAYM_2953_BLOCK_REJECT_LEAD_IF_ORDER_PURCHASED_20240724_TEMP = 'paym-2953_block-reject-lead-if-order-purchased_20240724_temp',
  PAYM_2957_SHOW_CREDIT_AMOUNT_20240725_TEMP = 'paym-2957_show-credit-shell-amount_20240725_temp',

  BROK_35_ENABLE_ACCOUNTING_PAGES_20240715_TEMP = 'brok-35_enable-accounting-pages_20240715_temp',
  BROK_122_UPDATE_CUSTOMER_ON_UPDATE_OF_QC_POLICY_20240808_TEMP = 'brok-122_update-customer-on-update-of-qc-policy_20240808_temp',
  BROK_177_ENABLE_ALL_ACCOUNTING_PAGE_20240815_TEMP = 'brok_177_enable_all_accounting_page_20240815_temp',

  PAYM_2396_ENABLE_NEW_CREATE_PAYMENT = 'paym-2396_enable_new_create_payment',
  PAYM_2902_FE_PARTIAL_CHANGE_ORDER_20240626_TEMP = 'paym-2902_fe-partial-change-order_20240626_temp',
  BROK_112_CANCELLATION_REASON_NEW_OPTIONS_20240803_TEMP = 'brok-112_cancellation_reason_new_options_20240803_temp',
  BROK_258_ACTUAL_REFUND_AMOUNT_20240828_TEMP = 'brok-258_actual_refund_amount_20240828_temp',
  BROK_243_SWITCH_CANCELLATION_ENDPOINT_20240905_temp = 'brok-243_switch_cancellation_endpoint_20240905_temp',
  BROK_510_AI_TRANSCRIBES_20241022_TEMP = 'brok-510-ai_transcribes_20241022_temp',
  BROK_210_ENABLE_ORDER_LISTING_PAGE_241024_TEMP = 'brok_210_enable_order_listing_page_241024_temp',
  BROK_782_ALLOW_ONLY_SALES_AGENT_TO_PURCHASE_29102024_TEMP = 'brok_782_allow-only-sales-agent-to-purchase_29102024_temp',
  BROK_625_ENABLE_TRANSACTION_LISTING_PAGE_20241113_TEMP = 'brok-625_enable-transaction-listing-page_20241113_temp',
  BROK_902_ADD_NEW_CONFIG_GROUP_IN_AUTO_ASSIGN_20241113_TEMP = 'brok-904_add-new-config-group-in-auto-assign_20241113_temp',
  BROK_316_HIDE_PRODUCT_COLUMN_ON_LEAD_IMPORT_PAGE_20241119_TEMP = 'brok-316_hide-product-column-on-lead-import-page_20241119_temp',
  BROK_446_ENABLE_CAMPAIGN_PAGE_FOR_HEALTH_20241211_TEMP = 'brok-446_enable-campaign-page-for-health_20241211_temp',
  BROK_1007_IS_NEW_PACKAGE_LISTING_PAGE_ENABLED_20241120_TEMP = 'brok-1007_is-new-package-listing-page_20241120_temp',
  BROK_1138_SHOW_PAYMENT_FLOW_FOR_HEALTH_20241210_TEMP = 'brok-1138_show-payment-flow-for-health_20241210_temp',
  BROK_1208_ENABLE_CONTRACT_PAGE_FOR_HEALTH_20241213_TEMP = 'brok-1208_enable-conctract-page-for-health_20241213_temp',
  BROK_1052_ENABLE_PRODUCT_DROPDOWN_ON_LEAD_SOURCE_MODAL_20241223_TEMP = 'brok-1052_enable-product-dropdown-on-lead-source-modal_20241223_temp',
  LEAD_4764_USE_NEW_MODAL_FOR_CREATE_UPDATE_USER_20240129_TEMP = 'lead-4764_use-new-modal-for-create-update-user_20240129_temp',
}

export default FeatureFlags;
